import { schemas as s, validate } from "validia";
import { ObjectUtils } from "../../../../../../../common/object-utils";
import { PropertyValue } from "./properties";

export namespace UserReport {
    /**
     * {@link UserReport} クラスの初期化パラメーター
     */
    export interface ConstructionParameters {
        /**
         * 識別子
         *
         * @default a random UUID
         */
        readonly id?: string | undefined;
        /**
         * 緊急度
         *
         * @default D
         */
        readonly priority?: UserReport.Priority | undefined;
        /**
         * 報告者の ID (email)
         *
         * @default "unknown@example.com"
         */
        readonly reporterId?: string | undefined;
        /**
         * 報告者の名前
         *
         * @default "unknown"
         * @deprecated ハリボテ用
         */
        readonly reporterName?: string | undefined;
        /**
         * 担当者の ID (email)
         *
         * @default undefined
         */
        readonly ownerId?: string | undefined;
        /**
         * 状態
         *
         * @default "triage"
         */
        readonly state?: UserReport.State | undefined;
        /**
         * 表題
         *
         * @default "untitle"
         */
        readonly subject?: string | undefined;
        /**
         * 報告日時
         *
         * @default new Date()
         */
        readonly time?: Date | number | string | undefined;
        /**
         * プロパティ値
         *
         * @default an empty map
         */
        readonly values?: Record<string, PropertyValue> | undefined;
    }

    /**
     * 報告の優先度
     */
    export type Priority = "A" | "B" | "C" | "D";

    /**
     * 報告の処理状態
     *
     * - `triage` .......... 受付
     * - `investigating` ... 調査中
     * - `doing` ........... 対応中
     * - `confirming` ...... 確認中
     * - `closed` .......... 完了
     */
    export type State =
        | "triage"
        | "investigating"
        | "doing"
        | "confirming"
        | "closed";
}

/**
 * ユーザーからの報告を表すモデル
 */
export class UserReport {
    /** 識別子 */
    readonly id: string;
    /** 担当者の ID (email) */
    readonly ownerId: string | undefined;
    /** 緊急度 */
    readonly priority: UserReport.Priority;
    /** 報告者の ID (email) */
    readonly reporterId: string;
    /**
     * 報告者の名前
     * @deprecated ハリボテ用
     */
    readonly reporterName: string;
    /** 状態 */
    readonly state: UserReport.State;
    /** 表題 */
    readonly subject: string;
    /** 報告日時 */
    readonly time: Date;
    /** プロパティ値 */
    readonly values: Record<string, PropertyValue>;

    /**
     * 新しい {@link UserReport} インスタンスを初期化します。
     *
     * @param parameters 初期化パラメーター
     */
    constructor(parameters: UserReport.ConstructionParameters = {}) {
        const {
            id = crypto.randomUUID(),
            ownerId,
            priority = "D",
            reporterId = "unknown@example.com",
            reporterName = "unknown",
            state = "triage",
            subject = "untitled",
            time = new Date(),
            values = Object.create(null),
        } = parameters;

        this.id = id;
        this.ownerId = ownerId;
        this.priority = priority;
        this.reporterId = reporterId;
        this.reporterName = reporterName;
        this.state = state;
        this.subject = subject;
        this.time = new Date(time);
        this.values = values;
    }

    /**
     * JSON オブジェクトを解析して {@link UserReport} インスタンスを作
     * 成します。
     *
     * @param data 解析する値
     * @returns 解析結果
     */
    static parse(this: void, data: unknown): UserReport {
        validate(UserReportSchema, data, { name: "data" });

        return new UserReport(data);
    }

    equals(that: UserReport): boolean {
        return (
            this.id === that.id &&
            this.ownerId === that.ownerId &&
            this.priority === that.priority &&
            this.reporterId === that.reporterId &&
            this.reporterName === that.reporterName &&
            this.state === that.state &&
            this.subject === that.subject &&
            this.time === that.time &&
            ObjectUtils.deepEqual(this.values, that.values)
        );
    }

    with(parameters: UserReport.ConstructionParameters): UserReport {
        const {
            id,
            ownerId,
            priority,
            reporterId,
            reporterName,
            state,
            subject,
            time,
            values,
        } = parameters;
        const next = new UserReport({
            id: id ?? this.id,
            ownerId: ownerId ?? this.ownerId,
            priority: priority ?? this.priority,
            reporterId: reporterId ?? this.reporterId,
            reporterName: reporterName ?? this.reporterName,
            state: state ?? this.state,
            subject: subject ?? this.subject,
            time: time ?? this.time,
            values: values ?? this.values,
        });

        return this.equals(next) ? this : next;
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const UserReportSchema = s.object({
    id: s.string(),
    ownerId: s.string(),
    priority: s.enum("A" as const, "B" as const, "C" as const, "D" as const),
    reporterId: s.string(),
    reporterName: s.string(),
    state: s.enum(
        "triage" as const,
        "investigating" as const,
        "doing" as const,
        "confirming" as const,
        "closed" as const,
    ),
    subject: s.string(),
    time: s.string(),
    values: s.record(s.anyOf(s.string(), s.array(s.string()))),
});

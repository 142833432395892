import { schemas as s } from "validia";
import { PropertyValue } from "../properties";

export namespace PropertyConditionIncludes {
    /**
     * {@link PropertyConditionIncludes} クラスの初期化パラメーター
     */
    export interface ConstructionParameters {
        /** 判定に使うプロパティの識別子 */
        readonly id: string;
        /** 判定に使う値 */
        readonly value: string;
    }
}

/**
 * 文字列開始条件式
 */
export interface PropertyConditionIncludes {
    /** 判定に使うプロパティの識別子 */
    readonly id: string;
    /** 種別 */
    readonly type: "includes";
    /** 判定に使う値 */
    readonly value: string;
}

/**
 * 文字列開始条件式のスキーマ
 */
export const PropertyConditionIncludesSchema = s.object(
    {
        id: s.string(),
        type: s.enum("includes" as const),
        value: s.string(),
    },
    { required: true },
);

/**
 * 値が条件式を満たすか判定します。
 *
 * @param condition 条件式
 * @param values 判定する値
 * @returns 判定結果
 */
export function testPropertyConditionIncludes(
    condition: PropertyConditionIncludes,
    values: Record<string, PropertyValue>,
): boolean {
    const { id, value } = condition;
    const target = values[id];

    return typeof target === "string"
        ? target.includes(value)
        : Array.isArray(target)
        ? target.includes(value)
        : false;
}

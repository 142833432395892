import { schemas as s } from "validia";
import { PropertyConditionSchema } from "../conditions";
import { PropertyCommons } from "./common";

export namespace MultiTextProperty {
    /**
     * {@link MultiTextProperty} クラスの初期化パラメーター
     */
    export interface ConstructionParameters
        extends PropertyCommons.ConstructionParameters {
        /** 初期値 */
        readonly defaultValue?: readonly string[] | undefined;
        /** 種別 */
        readonly type?: "multi-text";
    }
}

/**
 * 複数入力可能な文字列プロパティ
 */
export class MultiTextProperty extends PropertyCommons {
    /** スキーマ定義 */
    static Schema = s.object(
        {
            condition: PropertyConditionSchema,
            id: s.string(),
            label: s.string(),
            type: s.enum("multi-text" as const),
        },
        { required: ["id", "label", "type"] },
    );
}

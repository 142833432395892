import { SetRequired } from "type-fest";
import { schemas as s } from "validia";
import { PropertyValue } from "./properties/common";
import { DateTimeProperty } from "./properties/datetime";
import { MultiTextProperty } from "./properties/multi-text";
import { SelectProperty } from "./properties/select";
import { TextProperty } from "./properties/text";

export {
    DateTimeProperty,
    MultiTextProperty,
    PropertyValue,
    SelectProperty,
    TextProperty,
};

/** プロパティ定義 */
export type Property =
    | DateTimeProperty
    | MultiTextProperty
    | SelectProperty
    | TextProperty;
/** プロパティ定義の構築パラメーター */
export type PropertyConstructionParameters =
    | SetRequired<DateTimeProperty.ConstructionParameters, "type">
    | SetRequired<MultiTextProperty.ConstructionParameters, "type">
    | SetRequired<SelectProperty.ConstructionParameters, "type">
    | SetRequired<TextProperty.ConstructionParameters, "type">;
/** プロパティ定義のスキーマ */
export const PropertySchema = s.anyOf(
    DateTimeProperty.Schema,
    MultiTextProperty.Schema,
    SelectProperty.Schema,
    TextProperty.Schema,
);

/**
 * データを解析して条件式オブジェクトを作成します。
 *
 * @param data データ
 * @returns 条件式
 */
export function createProperty(data: PropertyConstructionParameters): Property {
    switch (data.type) {
        case "datetime":
            return new DateTimeProperty(data);
        case "multi-text":
            return new MultiTextProperty(data);
        case "select":
            return new SelectProperty(data);
        case "text":
            return new TextProperty(data);
        default:
            throw new Error("unknown type");
    }
}

export function propertyValueToString(
    value: PropertyValue,
): string | undefined {
    return value === undefined
        ? undefined
        : typeof value === "string"
        ? value
        : value.join(" ");
}

export function propertyValueToStringArray(
    value: PropertyValue,
): readonly string[] | undefined {
    return value === undefined
        ? undefined
        : typeof value === "string"
        ? value.split(/\s+/u)
        : value;
}

import { PropertyCondition, testPropertyCondition } from "../conditions";

/**
 * プロパティ値の型
 */
export type PropertyValue = string | readonly string[] | undefined;

export namespace PropertyCommons {
    /**
     * {@link PropertyCommons} クラスの初期化パラメーター
     */
    export interface ConstructionParameters {
        /** 表示条件 */
        readonly condition?: PropertyCondition | undefined;
        /** 初期値 */
        readonly defaultValue?: PropertyValue;
        /** 識別子 */
        readonly id: string;
        /** 項目の表示文字列 */
        readonly label: string;
    }
}

/**
 * プロパティ定義の共通項
 */
export abstract class PropertyCommons {
    readonly condition: PropertyCondition | undefined;
    readonly defaultValue: PropertyValue;
    readonly id: string;
    readonly label: string;

    /**
     * 新しい {@link PropertyCommons} インスタンスを初期化します。
     *
     * @param parameters 初期化パラメーター
     */
    constructor(parameters: PropertyCommons.ConstructionParameters) {
        const { condition, defaultValue, id, label } = parameters;

        this.condition = condition;
        this.defaultValue = defaultValue;
        this.id = id;
        this.label = label;
    }

    /**
     * 初期値を取得します。
     *
     * @returns 初期値
     */
    getDefaultValue(): PropertyValue {
        return this.defaultValue;
    }

    /**
     * 現在の値が条件にマッチするか判定します。
     *
     * @param values 現在の値
     * @returns 現在の値が条件にマッチすれば真、それ以外は偽
     */
    test(values: Record<string, PropertyValue>): boolean {
        return testPropertyCondition(this.condition, values);
    }
}

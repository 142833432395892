import { schemas as s } from "validia";
import {
    PropertyCondition,
    PropertyConditionSchema,
    testPropertyCondition,
} from "../conditions";
import { PropertyCommons, PropertyValue } from "./common";

export namespace SelectProperty {
    /**
     * {@link SelectProperty} クラスの初期化パラメーター
     */
    export interface ConstructionParameters
        extends PropertyCommons.ConstructionParameters {
        /** 最初から選択されている選択肢の`value`値 */
        readonly defaultValue?: string | undefined;
        /** 選択肢リスト */
        readonly options: readonly Option[];
        /** 種別 */
        readonly type?: "select";
    }

    /**
     * 選択肢プロパティの選択肢
     */
    export interface Option {
        /** 表示条件 */
        readonly condition?: PropertyCondition;
        /** 表示文字列 */
        readonly label?: string | undefined;
        /** 値 */
        readonly value: string;
    }
}

/**
 * 選択肢プロパティ
 */
export class SelectProperty extends PropertyCommons {
    /** スキーマ定義 */
    static Schema = s.object(
        {
            condition: PropertyConditionSchema,
            defaultOption: s.string(),
            id: s.string(),
            label: s.string(),
            options: s.array(
                s.object(
                    {
                        condition: PropertyConditionSchema,
                        label: s.string(),
                        value: s.string(),
                    },
                    { required: ["value"] },
                ),
            ),
            type: s.enum("select" as const),
        },
        { required: ["id", "label", "options", "type"] },
    );

    readonly options: readonly SelectProperty.Option[];

    /**
     * 新しい {@link SelectProperty} インスタンスを初期化します。
     *
     * @param parameters 初期化パラメーター
     */
    constructor(parameters: SelectProperty.ConstructionParameters) {
        super(parameters);

        const { defaultValue, options } = parameters;
        if (
            defaultValue !== undefined &&
            options.every((o) => o.value !== defaultValue)
        ) {
            throw new Error(
                "'defaultValue' must be undefined or one of option's values",
            );
        }
        if (options.length === 0) {
            throw new Error("'options' must have one or more elements");
        }

        this.options = options;
    }

    getDefaultValue(): PropertyValue {
        return super.getDefaultValue() ?? this.options[0].value;
    }

    /**
     * 表示するべき選択肢リストを取得します。
     *
     * @param values 現在の値
     * @returns 表示するべき選択肢リスト
     */
    getVisibleOptions(
        values: Record<string, PropertyValue>,
    ): readonly SelectProperty.Option[] {
        return this.options.filter((option) =>
            testPropertyCondition(option.condition, values),
        );
    }
}

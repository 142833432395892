import { schemas as s } from "validia";
import { PropertyConditionSchema } from "../conditions";
import { PropertyCommons } from "./common";

export namespace DateTimeProperty {
    /**
     * {@link DateTimeProperty} クラスの初期化パラメーター
     */
    export interface ConstructionParameters
        extends PropertyCommons.ConstructionParameters {
        /** 初期値 */
        readonly defaultValue?: string | undefined;
        /** 種別 */
        readonly type?: "datetime";
    }
}

/**
 * 文字列プロパティ
 */
export class DateTimeProperty extends PropertyCommons {
    /** スキーマ定義 */
    static Schema = s.object(
        {
            condition: PropertyConditionSchema,
            id: s.string(),
            label: s.string(),
            type: s.enum("datetime" as const),
        },
        { required: ["id", "label", "type"] },
    );
}

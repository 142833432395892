import { schemas as s } from "validia";
import { testPropertyCondition } from "../conditions";
import { PropertyValue } from "../properties";
import { PropertyConditionEq, PropertyConditionEqSchema } from "./eq";
import {
    PropertyConditionIncludes,
    PropertyConditionIncludesSchema,
} from "./includes";
import {
    PropertyConditionStartsWith,
    PropertyConditionStartsWithSchema,
} from "./starts-with";

export namespace PropertyConditionUnion {
    export type SubCondition =
        | PropertyConditionEq
        | PropertyConditionIncludes
        | PropertyConditionStartsWith;

    /**
     * {@link PropertyConditionUnion} クラスの初期化パラメーター
     */
    export interface ConstructionParameters {
        /** 合成する条件 */
        readonly conditions: readonly SubCondition[];
    }
}

/**
 * 等号条件式
 */
export interface PropertyConditionUnion {
    /** 種別 */
    readonly type: "and" | "union";
    /** 合成する条件 */
    readonly conditions: readonly PropertyConditionUnion.SubCondition[];
}

/**
 * 等号条件式のスキーマ
 */
export const PropertyConditionUnionSchema = s.object(
    {
        type: s.enum("and" as const, "union" as const),
        value: s.array(
            s.anyOf(
                PropertyConditionEqSchema,
                PropertyConditionIncludesSchema,
                PropertyConditionStartsWithSchema,
            ),
        ),
    },
    { required: true },
);

/**
 * 値が条件式を満たすか判定します。
 *
 * @param condition 条件式
 * @param values 判定する値
 * @returns 判定結果
 */
export function testPropertyConditionUnion(
    condition: PropertyConditionUnion,
    values: Record<string, PropertyValue>,
): boolean {
    return condition.conditions.every((c) => testPropertyCondition(c, values));
}

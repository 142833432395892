import React, { useCallback, useMemo } from "react";
import { Node } from "../../../../../../API";
import { useStorageValue } from "../../../../../hooks/use-storage-value";
import { UserReport } from "./model";

export namespace useUserReportList {
    export interface Result {
        userReports: readonly UserReport[];
        createUserReport: (userReport: UserReport) => Promise<void>;
        deleteUserReport: (userReport: UserReport) => Promise<void>;
        updateUserReport: (userReport: UserReport) => Promise<void>;
    }
}

export function useUserReportList(
    nodeId: Node["id"],
): useUserReportList.Result {
    const [jsonText, setJsonText] = useStorageValue(
        localStorage,
        `0b6742f8-289a-42d5-92ec-84183a86088e:${nodeId}`,
    );
    const userReports = useMemo(() => parse(jsonText), [jsonText]);

    const createUserReport = useCallback(
        (userReport: UserReport) => create(userReport, setJsonText),
        [setJsonText],
    );

    const deleteUserReport = useCallback(
        (userReport: UserReport) => delete_(userReport, setJsonText),
        [setJsonText],
    );

    const updateUserReport = useCallback(
        (userReport: UserReport) => update(userReport, setJsonText),
        [setJsonText],
    );

    return {
        createUserReport,
        deleteUserReport,
        updateUserReport,
        userReports,
    };
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

function parse(jsonText: string): readonly UserReport[] {
    try {
        const data = JSON.parse(jsonText || "[]");
        if (Array.isArray(data)) {
            return data.map(UserReport.parse);
        }
    } catch (error: unknown) {
        console.error("ユーザー報告のデータが不正でした。", error, jsonText);
    }

    return [];
}

function create(
    userReport: UserReport,
    setJsonText: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setJsonText((prev) => {
            try {
                const userReports = parse(prev);
                if (userReports.some((u) => u.id === userReport.id)) {
                    throw new Error(`'${userReport.id}' exists already`);
                }
                return JSON.stringify([...userReports, userReport]);
            } catch (error: unknown) {
                reject(error);
                return prev;
            } finally {
                resolve();
            }
        });
    });
}

function delete_(
    userReport: UserReport,
    setJsonText: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setJsonText((prev) => {
            try {
                const userReports = parse(prev);
                const index = userReports.findIndex(
                    (u) => u.id === userReport.id,
                );
                return index === -1
                    ? prev
                    : JSON.stringify([...userReports].splice(index, 1));
            } catch (error: unknown) {
                reject(error);
                return prev;
            } finally {
                resolve();
            }
        });
    });
}

function update(
    userReport: UserReport,
    setJsonText: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setJsonText((prev) => {
            try {
                const userReports = parse(prev);
                const index = userReports.findIndex(
                    (u) => u.id === userReport.id,
                );
                if (index === -1) {
                    throw new Error(`'${userReport.id}' was not found`);
                }
                return JSON.stringify(
                    [...userReports].splice(index, 1, userReport),
                );
            } catch (error: unknown) {
                reject(error);
                return prev;
            } finally {
                resolve();
            }
        });
    });
}

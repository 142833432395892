import { schemas as s } from "validia";
import { ArrayUtils } from "../../../../../../../../common/array-utils";
import { PropertyValue } from "../properties";

export namespace PropertyConditionEq {
    /**
     * {@link PropertyConditionEq} クラスの初期化パラメーター
     */
    export interface ConstructionParameters {
        /** 判定に使うプロパティの識別子 */
        readonly id: string;
        /** 判定に使う値 */
        readonly value: string | readonly string[];
    }
}

/**
 * 等号条件式
 */
export interface PropertyConditionEq {
    /** 判定に使うプロパティの識別子 */
    readonly id: string;
    /** 種別 */
    readonly type?: "eq" | undefined;
    /** 判定に使う値 */
    readonly value: string | readonly string[];
}

/**
 * 等号条件式のスキーマ
 */
export const PropertyConditionEqSchema = s.object(
    {
        id: s.string(),
        type: s.enum("eq" as const),
        value: s.anyOf(s.string(), s.array(s.string())),
    },
    { required: true },
);

/**
 * 値が等価条件式を満たすか判定します。
 *
 * @param condition 等価条件式
 * @param values 判定する値
 * @returns 判定結果
 */
export function testPropertyConditionEq(
    condition: PropertyConditionEq,
    values: Record<string, PropertyValue>,
): boolean {
    const { id, value } = condition;
    const target = values[id];

    if (typeof value === "string") {
        return target === value;
    }
    return Array.isArray(target) && ArrayUtils.equals(target, value);
}

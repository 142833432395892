import { schemas as s } from "validia";
import { PropertyConditionSchema } from "../conditions";
import { PropertyCommons } from "./common";

export namespace TextProperty {
    /**
     * {@link TextProperty} クラスの初期化パラメーター
     */
    export interface ConstructionParameters
        extends PropertyCommons.ConstructionParameters {
        /** 初期値 */
        readonly defaultValue?: string | undefined;
        /** 複数行フラグ */
        readonly multiline?: boolean;
        /** 読取専用フラグ */
        readonly readOnly?: boolean;
        /** 種別 */
        readonly type?: "text";
    }
}

/**
 * 文字列プロパティ
 */
export class TextProperty extends PropertyCommons {
    /** スキーマ定義 */
    static Schema = s.object(
        {
            condition: PropertyConditionSchema,
            id: s.string(),
            label: s.string(),
            multiline: s.boolean(),
            readOnly: s.boolean(),
            type: s.enum("text" as const),
        },
        { required: ["id", "label", "type"] },
    );

    readonly multiline: boolean;
    readonly readOnly: boolean;

    /**
     * 新しい {@link TextProperty} インスタンスを初期化します。
     *
     * @param parameters 初期化パラメーター
     */
    constructor(parameters: TextProperty.ConstructionParameters) {
        super(parameters);

        const { multiline = false, readOnly = false } = parameters;

        this.multiline = multiline;
        this.readOnly = readOnly;
    }
}

import { useMemo } from "react";
import {
    createProperty,
    PropertyConstructionParameters,
    UserReportDefinition,
} from "./model";

/**
 * 指定ノード種別に対応するプロパティ定義を取得します。
 *
 * @param nodeTypeId ノード種別ID
 * @returns プロパティ定義
 */
export function useUserReportDefinition(
    nodeTypeId: string,
): UserReportDefinition {
    return useMemo(
        () =>
            new UserReportDefinition({
                properties:
                    nodeTypeId === "computer-unit-node-type-4"
                        ? TransformerProperties.map(createProperty)
                        : nodeTypeId === "computer-unit-node-type-6"
                        ? Ipmat3sProperties.map(createProperty)
                        : SubstationProperties.map(createProperty),
            }),
        [nodeTypeId],
    );
}

const SubstationProperties: readonly PropertyConstructionParameters[] = [
    {
        id: "description",
        type: "text",
        label: "異常詳細",
        multiline: true,
    },
];

const TransformerProperties: readonly PropertyConstructionParameters[] = [
    {
        id: "time",
        type: "datetime",
        label: "発生日時",
    },
    {
        id: "parts",
        type: "select",
        label: "機種名",
        options: [{ value: "制御装置他" }, { value: "その他" }],
    },
    {
        id: "partsOther",
        condition: { type: "eq", id: "parts", value: "その他" },
        type: "text",
        label: "機種名(その他)",
    },
    {
        id: "model",
        type: "select",
        label: "型式",
        options: [
            { value: "IPMAT ⅢS" },
            { value: "PI・DFP-MS" },
            { value: "ATC6030" },
            {
                value: "光ILCB・IL-MX",
                condition: { type: "eq", id: "parts", value: "制御装置他" },
            },
            {
                value: "光ILCB・IL-MX » 自動ロックランプ",
                condition: { type: "eq", id: "parts", value: "制御装置他" },
            },
            {
                value: "光ILCB・IL-MX » 43ALランプ",
                condition: { type: "eq", id: "parts", value: "制御装置他" },
            },
            { value: "その他" },
        ],
    },
    {
        id: "modelOther",
        condition: { type: "eq", id: "model", value: "その他" },
        type: "text",
        label: "型式(その他)",
    },
    {
        id: "serialNumber",
        type: "text",
        label: "製造番号",
    },
    {
        id: "projectNumber",
        type: "text",
        label: "工号",
    },
    {
        id: "summary",
        type: "text",
        label: "異常内容",
    },
    {
        id: "description",
        type: "text",
        label: "異常詳細",
        multiline: true,
    },
];

const Ipmat3sProperties: readonly PropertyConstructionParameters[] = [
    {
        id: "time",
        type: "datetime",
        label: "発生日時",
    },
    {
        id: "parts",
        type: "select",
        label: "機種名",
        options: [
            { value: "機器保護リレー" },
            { value: "機器保護リレー » 装置異常ランプ" },
            { value: "アナログリレー" },
            { value: "ディジタルリレー" },
            { value: "ディジタルリレー » 保護対象：用賀環線" },
            { value: "VQ 制御装置" },
            { value: "VQ 制御装置 » 装置異常ランプ" },
            { value: "VQ 制御装置 » HI パネル表示" },
            { value: "ループリレー" },
            { value: "送電線保護リレー" },
            { value: "配電線保護リレー" },
            { value: "テレコンハードウェア" },
            { value: "その他" },
        ],
        defaultValue: "ディジタルリレー",
    },
    {
        id: "partsOther",
        condition: { type: "eq", id: "parts", value: "その他" },
        type: "text",
        label: "機種名(その他)",
    },
    {
        id: "model",
        type: "select",
        label: "型式",
        options: [
            { value: "IPMAT ⅢS" },
            { value: "PI・DFP-MS" },
            {
                value: "VQ・VQ-MS",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "VQ 制御装置",
                },
            },
            {
                value: "MRC・MF86D-15C",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "機器保護リレー",
                },
            },
            {
                value: "ミニクラ・MF87L-01",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "機器保護リレー",
                },
            },
            {
                value: "ミニクラ・8123-C2BA",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "アナログリレー",
                },
            },
            {
                value: "66kV端末・LD6C-MS",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "ループリレー",
                },
            },
            { value: "ATC6030" },
            {
                value: "PTC6032B",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "テレコンハードウェア",
                },
            },
            {
                value: "PTC6033B-A",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "テレコンハードウェア",
                },
            },
            {
                value: "PTC6131B",
                condition: {
                    type: "startsWith",
                    id: "parts",
                    value: "テレコンハードウェア",
                },
            },
            { value: "その他" },
        ],
    },
    {
        id: "modelOther",
        condition: { type: "eq", id: "model", value: "その他" },
        type: "text",
        label: "型式(その他)",
    },
    {
        id: "serialNumber",
        type: "text",
        label: "製造番号",
    },
    {
        id: "projectNumber",
        type: "text",
        label: "工号",
    },
    {
        id: "code",
        type: "multi-text",
        label: "異常コード",
    },
    {
        id: "state",
        type: "select",
        label: "異常状態",
        options: [
            { value: "発生中" },
            { value: "復帰済" },
            { value: "発生復帰を繰り返す" },
            { value: "不明" },
        ],
    },
    {
        id: "summary",
        type: "text",
        label: "異常内容",
    },
    {
        id: "description",
        type: "text",
        label: "異常詳細",
        multiline: true,
    },
    {
        id: "inference",
        type: "text",
        label: "異常原因推定",
        readOnly: true,
        defaultValue: "トリップ基板（KK21E-08）の故障と推測されます",
        condition: {
            type: "and",
            conditions: [
                {
                    type: "eq",
                    id: "parts",
                    value: "ディジタルリレー » 保護対象：用賀環線",
                },
                {
                    type: "eq",
                    id: "model",
                    value: "IPMAT ⅢS",
                },
                {
                    type: "includes",
                    id: "code",
                    value: "3056",
                },
                {
                    type: "eq",
                    id: "state",
                    value: "発生中",
                },
            ],
        },
    },
];

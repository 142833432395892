import { schemas as s } from "validia";
import {
    PropertyConditionUnion,
    PropertyConditionUnionSchema,
    testPropertyConditionUnion,
} from "./conditions/and";
import {
    PropertyConditionEq,
    PropertyConditionEqSchema,
    testPropertyConditionEq,
} from "./conditions/eq";
import {
    PropertyConditionIncludes,
    PropertyConditionIncludesSchema,
    testPropertyConditionIncludes,
} from "./conditions/includes";
import {
    PropertyConditionStartsWith,
    PropertyConditionStartsWithSchema,
    testPropertyConditionStartsWith,
} from "./conditions/starts-with";
import { PropertyValue } from "./properties";

/** 条件式 */
export type PropertyCondition =
    | PropertyConditionEq
    | PropertyConditionIncludes
    | PropertyConditionStartsWith
    | PropertyConditionUnion;
/** 条件式のスキーマ */
export const PropertyConditionSchema = s.anyOf(
    PropertyConditionEqSchema,
    PropertyConditionIncludesSchema,
    PropertyConditionStartsWithSchema,
    PropertyConditionUnionSchema,
);

/**
 * 値が条件式を満たすか判定します。
 *
 * @param condition 条件式
 * @param values 判定する値
 * @returns 判定結果
 */
export function testPropertyCondition(
    condition: PropertyCondition | undefined,
    values: Record<string, PropertyValue>,
): boolean {
    if (condition === undefined) {
        return true;
    }

    switch (condition.type) {
        case undefined:
        case "eq":
            return testPropertyConditionEq(condition, values);

        case "includes":
            return testPropertyConditionIncludes(condition, values);

        case "startsWith":
            return testPropertyConditionStartsWith(condition, values);

        case "union":
        case "and":
            return testPropertyConditionUnion(condition, values);

        default:
            throw new Error("unknown type");
    }
}

import { schemas as s, validate } from "validia";
import {
    createProperty,
    Property as Property0,
    PropertyConstructionParameters,
    PropertySchema,
    PropertyValue,
} from "./properties";

export namespace UserReportDefinition {
    /** プロパティ定義 */
    export type Property = Property0;

    /** 初期化パラメーター */
    export interface ConstructionParameters {
        /** ユーザーに入力してもらう項目の定義 */
        readonly properties?: readonly Property[] | undefined;
    }
}

/**
 * 機種毎に決定される、ユーザーに入力してもらう項目の定義
 */
export class UserReportDefinition {
    /** ユーザーに入力してもらう項目の定義 */
    readonly properties: readonly UserReportDefinition.Property[];

    /**
     * 新しい {@link UserReportDefinition} インスタンスを初期化します。
     *
     * @param parameters データ
     */
    constructor(parameters: UserReportDefinition.ConstructionParameters = {}) {
        const { properties = [] } = parameters;

        this.properties = properties;
    }

    /**
     * JSON オブジェクトを解析して {@link UserReportDefinition} インスタンスを作
     * 成します。
     *
     * @param data 解析する値
     * @returns 解析結果
     */
    static parse(this: void, data: unknown): UserReportDefinition {
        validate(UserReportDefinitionSchema, data);

        return new UserReportDefinition({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- 型計算が深すぎてエラーになってしまうので仕方なく
            properties: (data as any).properties?.map(
                (p: PropertyConstructionParameters) => createProperty(p),
            ),
        });
    }

    /**
     * 現在の値に対して、表示すべきプロパティのリストを取得します。
     *
     * @param values 現在の値
     */
    getVisibleProperties(
        values: Record<string, PropertyValue>,
    ): readonly UserReportDefinition.Property[] {
        return this.properties.filter((p) => p.test(values));
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const UserReportDefinitionSchema = s.object(
    {
        properties: s.array(PropertySchema),
    },
    { required: true },
);
